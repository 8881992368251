<template>
  <ReportBase title="My Conversation" :is-loading="isLoading" class="my-conversation">
    <template #afterTitle>
      <DropdownWrapper class="my-conversation__dropdown" name="contentOptions" :options="coversationOptions"
        v-model="currentContent" :searchable="false" no-clear no-border />
    </template>

    <template #content v-if="tableContent">
      <TableContent :columnDefs="tableContent.columnDefs" :defaultColDef="tableContent.defaultColDef"
        :rowData="tableContent.rowData" />
    </template>
  </ReportBase>
</template>

<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import CubeRequestMixin from "@/components/report/common/CubeRequestMixin";

import DropdownWrapper from '@/components/report/common/DropdownWrapper.vue';
import ReportBase from '@/components/report/tabular/ReportBase';
import TableContent from '@/components/report/tabular/TableContent.vue';
import { responseToTable } from '@/components/report/tabular/cubeDataHandler';

const REPORT_IDS = {
  STANDARD_RESPONSES: 'DHR05_1',
  CUSTOM_RESPONSES: 'DHR05_2'
}

export default {
  name: 'MyConversation',

  mixins: [NotifyMixin, CubeRequestMixin],

  components: {
    TableContent,
    ReportBase,
    DropdownWrapper,
  },

  data() {
    return {
      isLoading: false,
      tableContent: null,
      currentContent: null,
      coversationOptions: [
        {
          value: 'Standard responses',
          key: 'STANDARD_RESPONSES'
        },
        {
          value: 'Custom Responses',
          key: 'CUSTOM_RESPONSES'
        },
      ]
    }
  },

  watch: {
    currentContent() {
      this.fetchContent()
    }
  },

  methods: {
    async fetchContent() {
      this.isLoading = true;

      try {
        const response = await this.fetchCubeData(REPORT_IDS[this.currentContent]);

        this.tableContent = responseToTable(response);

        this.isLoading = false;
      } catch (error) {
        this.notifyError(error.message);
      }
    }
  },

  mounted() {
    this.currentContent = 'STANDARD_RESPONSES';
  },
};
</script>

<style scoped>
.my-conversation .my-conversation__dropdown {
  @apply w-auto pl-3 pt-0 pb-0;

  padding-top: 2px;
}
</style>